<template>
    <div>
        <CRow class="mb-3">
            <CCol sm="12" md="6" lg="6"><!--infoPersonal-->
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader>
                        <div class="card-header-actions">
                            <CLink 
                                v-tippy="{ 
                                    content: verMasInfoPersonal?'Ocultar':'Ver'+' más Información',
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                class="card-header-action btn-minimize float-right" 
                                @click="verMasInfoPersonal = !verMasInfoPersonal">
                                <CIcon :name="`cil-chevron-${verMasInfoPersonal ? 'top':'bottom' }`"/>
                            </CLink>
                        </div>
                        <CRow>
                            <CCol><strong>Información Personal</strong></CCol>
                        </CRow>
                    </CCardHeader>
                    <CCollapse :show="isCollapsedPersonal" :duration="400">
                        <CCardBody>
                            <CargandoInfo :mostrarCarga="isLoadingInfoPersonal" :mensajeInfo="'Obteniendo su Información...'" />
                        
                            <!--<CRow v-show="isLoadingInfoPersonal">
                                <CCol>
                                    <center>
                                        <img src="/img/logo2.png" size="custom-size" :height="30" class="imgr mt-3 mb-3" /> Obteniendo su Información...
                                    </center>
                                </CCol>
                            </CRow>-->
                            <CCollapse :show="verMasInfoPersonal" :duration="400">
                                <CRow>
                                    <CCol md="12">
                                        <CRow>
                                            <CCol md="3" v-bind:key="prop" v-for="prop in proopsInfoBooleans">
                                                <CSwitch
                                                    v-tippy="{ 
                                                        content: cambiarNombre(prop),
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                    class="mx-1" 
                                                    color="primary" 
                                                    :size="'sm'" 
                                                    :checked="$props[prop]"
                                                    variant="outline" 
                                                    shape="pill" 
                                                    v-bind="{labelOn: '\u2713', labelOff: '\u2715'}"
                                                    @update:checked="$props[prop] = !$props[prop]" />
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                    <CDropdownDivider />
                            </CCollapse>
                            <CRow v-if="!isLoadingInfoPersonal && infoTrabajador != null">
                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verNombre">
                                    <CInput
                                        v-tippy="{ 
                                            content:'Nombre',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.nombreTrabajador
                                        horizontal
                                        
                                    >
                                        <template #append-content><CIcon name="cil-user" /></template>
                                    </CInput>
                                </CCol>
                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verRut">
                                    
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.rutTrabajador
                                        horizontal                                        
                                        v-tippy="{
                                            content:'Rut',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilFeaturedPlaylist" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verMail">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.mailTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Correo',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon name="cil-envelope-open" /></template>
                                    </CInput>
                                </CCol>
                                    
                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verTelefono">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.telefonoTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Teléfono',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilPhone" /></template>
                                    </CInput>
                                </CCol>
                                
                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verDireccion">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.direccionTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Dirección',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilHouse" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verProfesion">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.profesionTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Profesión',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilSchool" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verEstadoCivil">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.estadoCivilTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Estado Civil',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilPeople" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoPersonal()>4?'6':'12'" v-show="verNacionalidad">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.nacionalidadTrabajador
                                        horizontal
                                        v-tippy="{
                                            content:'Nacionalidad',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilGlobeAlt" /></template>
                                    </CInput>
                                    
                                </CCol>

                            </CRow>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>

            <CCol sm="12" md="6" lg="6"><!--infoLaboral-->
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader>
                        
                        <div class="card-header-actions">
                            <CLink 
                                v-tippy="{
                                    content:verMasInfoLaboral?'Ocultar':'Ver'+' más Información',
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                class="card-header-action btn-minimize float-right" 
                                @click="verMasInfoLaboral = !verMasInfoLaboral">
                                <CIcon :name="`cil-chevron-${verMasInfoLaboral ? 'top':'bottom' }`"/>
                            </CLink>
                            
                        </div>

                        <CRow>
                            <CCol><strong>Información Laboral</strong></CCol>
                        </CRow>
                    </CCardHeader>
                    <CCollapse :show="isCollapsedLaboral" :duration="400">
                        <CCardBody>
                            <CCollapse :show="verMasInfoLaboral" :duration="400">
                                <CRow>
                                    <CCol md="12">
                                        <CRow>
                                            <CCol md="3" v-bind:key="prop" v-for="prop in proopsLabBooleans">
                                                <CSwitch
                                                    v-tippy="{
                                                        content:cambiarNombre(prop),
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                    class="mx-1" 
                                                    color="primary" 
                                                    :size="'sm'" 
                                                    :checked="$props[prop]"
                                                    variant="outline" 
                                                    shape="pill" 
                                                    v-bind="{labelOn: '\u2713', labelOff: '\u2715'}"
                                                    @update:checked="$props[prop] = !$props[prop]" />
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                                    <CDropdownDivider />
                            </CCollapse>
                            <CRow v-show="isLoadingInfoLaboral">
                                <CCol>
                                    <center>
                                        <img src="/img/logo2.png" size="custom-size" :height="30" class="imgr mt-3 mb-3" /> Obteniendo su Información...
                                    </center>
                                </CCol>
                            </CRow>
                            <CRow v-if="!isLoadingInfoLaboral && infoTrabajador != null">
                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verSucursal">
                                    <CInput                                        
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.sucursal
                                        horizontal
                                        
                                        v-tippy="{
                                            content:'Sucursal',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilBuilding" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verUnidad">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.unidad
                                        horizontal
                                        
                                        v-tippy="{
                                            content:'Unidad',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilLayers" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verSeccion">

                                    <CInput                                        
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.seccion
                                        horizontal       
                                        
                                        v-tippy="{
                                            content:'Sección',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"                 
                                    >
                                        <template #append-content><CIcon 
                                        :content="$options.freeSet.cilLibrary" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verCargo">

                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.cargo
                                        horizontal
                                        v-tippy="{
                                            content:'Cargo',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilLineStyle" /></template>
                                    </CInput>
                            
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verAfp">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.afp
                                        horizontal                                        
                                        v-tippy="{
                                            content:'AFP',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilMoney" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verPrevision">
                                    

                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.prevision
                                        horizontal
                                        
                                        v-tippy="{
                                            content: 'Previsión',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilHospital" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verMutual">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.mutual
                                        horizontal
                                        
                                        v-tippy="{
                                            content:'Mutual',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilIndustry" /></template>
                                    </CInput>
                                </CCol>

                                <CCol :md="verPropInfoLaboral()>4?'6':'12'" v-show="verCaja">
                                    <CInput
                                        size="sm"
                                        disabled
                                        :value=infoTrabajador.caja
                                        horizontal
                                        
                                        v-tippy="{
                                            content:' Caja',
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                        <template #append-content><CIcon :content="$options.freeSet.cilPhone" /></template>
                                    </CInput>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
        </CRow>
    </div>
            
  
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    
    import CargandoInfo from '@/views/utils/CargandoInfo'

    import { freeSet } from "@coreui/icons";

    export default {
        name: "InfoTrabajador",
        freeSet,
        props: {
            infoTrabajador: {type: Object, default: null},
            isLoadingInfoPersonal: {type: Boolean, default: false},
            isLoadingInfoLaboral: {type: Boolean, default: false},
            
            verNombre: {type: Boolean, default: true},
            verRut: {type: Boolean, default: true},
            verMail: {type: Boolean, default: true},
            verTelefono: {type: Boolean, default: true},
            verDireccion: {type: Boolean, default: true},
            verProfesion: {type: Boolean,default: true},
            verEstadoCivil: { type: Boolean,default: true},
            verNacionalidad: {type: Boolean,default: true},

            verSucursal: {type: Boolean,default: true},
            verUnidad: {type: Boolean,default: true},
            verSeccion: {type: Boolean,default: true},
            verCargo: {type: Boolean,default: true},
            verAfp: {type: Boolean,default: true},
            verPrevision: {type: Boolean,default: true},
            verMutual: {type: Boolean, default: true},
            verCaja: {type: Boolean, default: true},

        },
        components: {
            Multiselect,
            CargandoInfo
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                
                urlBase: cons.port+"://" + cons.ipServer,
                //infoTrabajador: null,
                isCollapsedPersonal: true,
                isCollapsedLaboral: true,
                
                verMasInfoPersonal: false,
                verMasInfoLaboral: false,

                proopsInfoBooleans: ["verNombre", "verRut", "verMail", "verTelefono", "verDireccion", "verProfesion", "verEstadoCivil", "verNacionalidad"],
                proopsLabBooleans: ["verSucursal", "verUnidad", "verSeccion", "verCargo", "verAfp", "verPrevision", "verMutual", "verCaja"],

            };
        },
        created: function () {
        },
        beforeMount() {
            this.verPropInfoLaboral()
            this.verPropInfoPersonal()
            /*if (this.empresaSeleccionada != null) {
                this.obtenerInfo()
            }*/
            /*var idInt = setInterval(() => {
                if(this.$cookie.get('userLogginToken') === null || this.$cookie.get('userLogginToken') === ""){
                    this.$router.push("/pages/login");
                } else{
                    this.getNotificaciones();
                }
                
            }, (60000 * 5));*/
            
        },
        methods: {
            verPropInfoLaboral: function(){
                var totalProp = 0
                var propsBool = ["verSucursal", "verUnidad", "verSeccion", "verCargo", "verAfp", "verPrevision", "verMutual", "verCaja"]
                for (var prop in this.$props) {
                    if (propsBool.includes(prop)){
                        if(this.$props[prop]){
                            totalProp += 1
                        }
                    }
                }
                return totalProp
            },
            verPropInfoPersonal: function(){
                var totalProp = 0
                var propsBool = ["verNombre", "verRut", "verMail", "verTelefono", "verDireccion", "verProfesion", "verEstadoCivil", "verNacionalidad"]
                for (var prop in this.$props) {
                    if (propsBool.includes(prop)){
                        if(this.$props[prop]){
                            totalProp += 1
                        }
                    }
                }
                return totalProp
            },
            existePropInfo: function(prop){
                return this.proopsInfoBooleans.includes(prop)
            },
            cambiarNombre: function(prop){
                return prop.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w+/g, match => match.charAt(0).toUpperCase() + match.slice(1)); // Convierte la primera letra de cada palabra en mayúscula
            }
            
        },
    };
</script>
